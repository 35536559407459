import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import Modal from 'react-modal'
// import TableRowSelect from '../components/TableRowSelect'
import TableRowSelectGroupBy from '../components/TableRowSelectGroupBy'
import ColumnFilter from '../components/ColumnFilter'
import ColumnMultipleFilter from '../components/ColumnMultipleFilter'
import moment from 'moment'
import ButtonRound from '../components/ButtonRound';

import IconReload from './../assets/img/ic-reload.png'

// let apiLocation = localStorage.getItem('authLocation');
let apiXKey = localStorage.getItem('authXkey')

function Shop() {
  const checkExpireDay = (dayVal) => {
    let expireDay = moment(new Date(dayVal)).format("YYYY-MM-DD HH:mm")
    let next7Days = moment().add(7, 'days').format("YYYY-MM-DD HH:mm");
    let currentDay = moment().format("YYYY-MM-DD HH:mm")

    // console.log('expireDay',expireDay)
    // console.log('next7Days',next7Days)
    // console.log('currentDay',currentDay)
    // console.log(moment(expireDay).isBetween(currentDay, next7Days),'-------------')
    return moment(new Date(expireDay)).isBetween(currentDay, next7Days);
  }

  // const checkStatus = (statusVal) => {
  //   let statusText = ''

  //   switch (statusVal) {
  //     case 'waiting':
  //       statusText = 'พร้อมใช้';
  //       break;

  //     case 'unused':
  //       statusText = 'พร้อมสแกน';
  //       break;

  //     case 'used':
  //       statusText = 'รอแลกคืน';
  //       break;

  //     case 'expired':
  //       statusText = 'หมดอายุแล้ว';
  //       break;

  //     case 'waiting-for-redeem':
  //       statusText = 'กำลังแลกคืน (ไม่สแกน)';
  //       break;

  //     case 'waiting-to-redeem':
  //       statusText = 'กำลังแลกคืน (ไม่สแกน)';
  //       break;
        
  //     case 'waiting-for-confirmation':
  //       statusText = 'กำลังแลกคืน';
  //       break;   
       
  //     case 'waiting-to-confirmation':
  //       statusText = 'กำลังแลกคืน';
  //       break; 
        
  //     case 'redeemed':
  //       statusText = 'แลกคืนแล้ว';
  //       break; 
        
  //     default:
  //       break;
  //   }

  //   return statusText
  // }

    const columns = React.useMemo(
      () => [
          // {
          //   // Make an expander cell
          //   Header: () => null, // No header
          //   id: 'expander', // It needs an ID
          //   Cell: ({ row }) => (
          //     <span {...row.getToggleRowExpandedProps()}>
          //       {row.isExpanded ? '👇' : '👉'}
          //     </span>
          //   ),
          // },
          {
            Header: "NO",
            accessor: "NO",
            minWidth: 60,
            Cell: (row) => {
                return <div>{Number(row.row.id) + 1}</div>;
            },
            // disableSortBy: true,
            // disableFilters: true,
          },
          {
            Header: 'ISMS',
            accessor: 'isms_code',
            minWidth: 100,
          },
          {
            Header: 'ชื่อ',
            accessor: 'name',
            minWidth: 140,
          },
          // {
          //   Header: 'เขต',
          //   accessor: 'province',
          //   minWidth: 140,
          // },
          {
            Header: 'จังหวัด',
            accessor: 'province',
            minWidth: 140,
          },
          {
            Header: 'อำเภอ',
            accessor: 'district',
            minWidth: 140,
          },
          {
            Header: 'ตำบล',
            accessor: 'subdistrict',
            minWidth: 140,
          },
          // {
          //   Header: 'ขั้นต่ำในการขาย',
          //   accessor: 'atleast',
          //   minWidth: 120,
          //   Cell: (row) => {
          //       return <div>{!!row.row.original.atleast?row.row.original.atleast.toLocaleString():0}</div>;
          //   },
          // },
          {
            Header: 'จำนวนคูปองที่มี',
            accessor: 'ecp_num',
            minWidth: 120,
            Cell: (row) => {
                return <div>{!!row.row.original.ecp_num?row.row.original.ecp_num.toLocaleString():0}</div>;
            },
          },
          {
            Header: 'ยังไม่ได้เก็บ',
            accessor: 'uncollected',
            minWidth: 120,
            Cell: (row) => {
                return <div>{!!row.row.original.uncollected?row.row.original.uncollected.toLocaleString():0}</div>;
            },
          },
          {
            Header: 'กำลังจะหมดอายุ',
            accessor: 'expin7day',
            minWidth: 120,
          },
          {
            Header: 'มูลค่าทั้งหมด',
            accessor: 'ecp_value',
            minWidth: 120,
            Cell: (row) => {
                return <div>{!!row.row.original.ecp_value?row.row.original.ecp_value.toLocaleString():0}</div>;
            },
          },
    ],[]
  )

  const columnsDetail = React.useMemo(
    () => [
      {
        id: 'ecp_checkbox',
        Header: 'ecp_checkbox',
        accessor: 'ecp_checkbox',
        minWidth: 120,
        Filter: ColumnFilter,
      },
      {
        Header: 'รหัส QR',
        accessor: 'qrcode',
        minWidth: 120,
        Filter: ColumnFilter,
      },
      {
        Header: 'แคมเปญ',
        accessor: 'campaign_name',
        minWidth: 220,
      },
      // {
      //   Header: 'รหัสคูปอง',
      //   accessor: 'ecp_id',
      //   minWidth: 220,
      //   // Filter: ColumnMultipleFilter,
      //   FilterMultiple: ColumnMultipleFilter,
      // },
      {
        Header: 'มูลค่า',
        accessor: 'ecp_value',
        minWidth: 120,
        Cell: (row) => {
            return <div>{!!row.value?row.value.toLocaleString():0}</div>;
        },
        Filter: ColumnFilter,
      },
      {
        Header: 'วันที่เปลี่ยนสถานะล่าสุด',
        accessor: 'laststatusdate',
        minWidth: 140,
        Cell: (row) => {
            return <div className={checkExpireDay(row.value)? 'coupon-expiring': 'conpon-normal'}>{moment(row.value).format('DD MMM YYYY HH:mm')==='Invalid date'?'':moment(row.value).format('DD MMM YYYY HH:mm')}</div>;
        },
        Filter: ColumnFilter,
      },
      {
        Header: 'วันหมดอายุ',
        accessor: 'end_date',
        minWidth: 140,
        Cell: (row) => {
            return <div className={checkExpireDay(row.value)? 'coupon-expiring': 'conpon-normal'}>{moment(row.value).format('DD MMM YYYY HH:mm')==='Invalid date'?'':moment(row.value).format('DD MMM YYYY HH:mm')}</div>;
        },
        Filter: ColumnFilter,
      },
      {
        id: 'coupon_status',
        Header: 'สถานะ',
        accessor: 'coupon_status',
        minWidth: 120,
        Cell: (row) => {
            // return <div>{checkStatus(row.row.original.ecp_status)}</div>;
            return <div>{row.value}</div>
        },
        Filter: ColumnFilter,
      },
    ],[])
    

    const [dataUser, setDataUser] = useState([])
    const [showTableDetail, setShowTableDetail] = useState(false)
    const [dataUserDetail, setDataUserDetail] = useState([])
    const [preDataUserDetail, setPreDataUserDetail] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [modalError, setModalError] = useState(false)
    

    useEffect(() => {
      setShowTableDetail(false)
      
      fetchUser()
      

      return ()=> { 
        setDataUser([])
      }

    }, [])

    const fetchUser =  async() => {
      setIsLoading(true)
      const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/ecp_users`, {
          method: 'GET',  
          // body: JSON.stringify(dataLogin),
          headers: {
              "x-application-secret-key": apiXKey,
              "Authorization": "Bearer "+localStorage.getItem('authToken'),
              "Content-Type": "application/json",
              "Accept": "application/json"
          }
      });

      res
      .json()
      .then(res => {
            setIsLoading(false)
            // console.log('res status:',res.status)
            // console.log('data ecp_users:',res.data)
            setDataUser(res.data)
      })
      .catch(err => {
          console.log(err);
          // window.location.href="/"
          setIsLoading(false)
          setModalError(true)
      });
    }

    const fetchUserDetail =  async(userId, rowValue) => {
      setIsLoading(true)
      setShowTableDetail(true)
      const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/ecp_detail/`+userId, {
          method: 'GET',  
          // body: JSON.stringify(dataLogin),
          headers: {
              "x-application-secret-key": apiXKey,
              "Authorization": "Bearer "+localStorage.getItem('authToken'),
              "Content-Type": "application/json",
              "Accept": "application/json"
          }
      });

      res
      .json()
      .then(res => {
            setIsLoading(false)
            // console.log('data ecp_detail:',res.data, rowValue)
            setDataUserDetail(res.data)
            setDataUser([rowValue])

            setPreDataUserDetail(res.data)

            // For api check coupon 
            localStorage.setItem('userDetailId',userId)
      })
      .catch(err => {
          console.log(err);
          // window.location.href="/"
          setIsLoading(false)
          setModalError(true)
      });
    }
    
    const onChangeFilterMultiple = (dataChanged) => {
      // console.log('hi, skywalker',dataChanged[0].value, preDataUserDetail[0].ecp_id)

      let dataArr = []
      for(let j=0; j<dataChanged.length; j++) {

        for(let i=0; i<preDataUserDetail.length; i++) {
          if(preDataUserDetail[i].campaign_name === dataChanged[j].value) {
            dataArr.push(preDataUserDetail[i])
          }
        }

      }
      setDataUserDetail(!dataArr.length?preDataUserDetail:dataArr )
    }


    const gotoDetail = (idVal, rowVal) => {
      // console.log('id:',idVal,'row value:',rowVal)
      fetchUserDetail(idVal, rowVal)
    }

    const gotoHome = () => {
      setShowTableDetail(false)
      fetchUser()
      console.log(dataUser)
    }

    const closeModal = () => {
      setModalError(false)
      window.location.href="/"
  }

    return (
        <div className="shop-content">
            <div className="header-content">
                <div className="left-content">
                    <h1>Store list</h1>
                    <p>
                      <span className="font-blue" onClick={()=>gotoHome()}>HOME</span>
                      
                      {
                        showTableDetail ?
                        <React.Fragment>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>/</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="font-blue" onClick={()=>gotoHome()}>STORE LIST</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>/</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>{dataUser[0].name}</span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>/</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span>STORE LIST</span>
                        </React.Fragment>
                      }
                    </p>
                </div>
                <div className="center-content">
                  <button className="btn-reload"  type="button" onClick={()=>{gotoHome()}}>
                    <img src={IconReload} alt="reload" />
                  </button>
                </div>
                <div className="right-content">
                    {/* <input type="text" placeholder="Search" /> */}
                    <div style={{width: '50%',}}/>
                </div>
            </div>
            <div className="mg-t-30">
                
                {
                  showTableDetail ? 
                  // Detail Table
                  <React.Fragment>
                    <Table columns={columns} data={dataUser} showPagination={false} isLoading={isLoading} colspan={columns.length}  />
                    <br/>
                    <p>Campaign Name</p>
                    <ColumnMultipleFilter preDataUserDetail={preDataUserDetail} onChanged={onChangeFilterMultiple} />
                    {/* <br/> */}
                    <div style={{marginBottom: '10px'}}></div>
                    <TableRowSelectGroupBy /*preDataUserDetail={preDataUserDetail}*/ onChanged={onChangeFilterMultiple} onFilteredChange={(o)=>console.log(o)} columns={columnsDetail} data={dataUserDetail} showPagination={true} isLoading={isLoading} colspan={columnsDetail.length} rowProps={
                        (row) => {
                          // console.log(row)
                          // if(row.original.ecp_status === 'used' || (row.original.ecp_status === 'unused' && row.original.allow_before_scan === 1) ) {
                          //   return ({
                          //     // onClick: () => { console.log(row.original.ecp_status,'-',row.original.ecp_id) },
                          //     // showCheckbox,
                          //     // style: {
                          //     //   pointerEvents: "pointer"
                          //     // },
                          //     className: 'showCheckbox'
                          //   })
                          // } else {
                          //   return ({
                          //     // style: {
                          //     //   pointerEvents: "none"
                          //     // },
                          //     className: 'hideCheckbox'
                          //   })
                          // }
                          
                      }
                      }  
                    />
                  </React.Fragment>
                  :
                  // Main Table
                  <Table columns={columns} data={dataUser} showPagination={true} isLoading={isLoading} colspan={columns.length} rowProps={
                    (row) => {
                      // console.log(row.values)
                      return ({
                        onClick: () => { gotoDetail(row.original.id, row.values); },
                        style: {
                          cursor: "pointer"
                        }
                      })
                    }
                  }  
                />
                }
            </div>

            <Modal 
                isOpen={modalError} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>ไม่สามารถทำรายการได้</p>
                    <br/>
                    <ButtonRound text="ปิด" color="blue" onClick={closeModal}/>
                </div>
            </Modal>
        </div>
    )
}

export default Shop
