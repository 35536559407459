import React,{ useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Statistic from './Statistic';
import Report from './Report';
import Shop from './Shop'

import ButtonRound from '../components/ButtonRound';

// import AuthContext from '../components/AuthContext';

import IconHeader from '../assets/img/ic-ticket-header.png'
import IconProfile from '../assets/img/ic-user.png'
import IconShop from '../assets/img/ic-store.svg'
import IconReport from '../assets/img/ic-report.svg'
import IconStats from '../assets/img/ic-stats.svg'
import IconMenu from '../assets/img/ic-menu.png'




// let windowParentLocation = ''

// console.log('windowParentLocation',windowParentLocation);
// console.log('window.location.origin',window.location.origin)


// let authDataObj = {}

function Home() {
    // const { authData } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)
    const [modalLogout, setModalLogout] = useState(false)
    const [username, setUsername] = useState('Username')
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [collapseMenu, setCollapseMenu] = useState(false)
    const [statusData, setStatusData] = useState({})
    
    const history = useHistory();
    // console.log(authDataObj)

    let apiLocation = localStorage.getItem('authLocation');
    let apiXKey = localStorage.getItem('authXkey')

    useEffect(() => {
        // authDataObj = JSON.parse(localStorage.getItem('authData'))
        setIsLoading(true)
        let unamounted = false

        // if(localStorage.getItem('authData')===null) {
        //     history.push('/')
        // }

        // console.log('apiLocation',apiLocation)
        // console.log('apiLocation localstorage',localStorage.getItem('authLocation'))

        const fetchStatus =  async() => {
            // console.log(apiXKey)
            // console.log(localStorage.getItem('authToken'))
            const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/status`, {
                method: 'GET',
                // body: JSON.stringify(dataLogin),
                headers: {
                    "x-application-secret-key": apiXKey,
                    "Authorization": "Bearer "+localStorage.getItem('authToken'),
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            res
            .json()
            .then(res => {
                if(!unamounted) {
                    // console.log('data status:',res)
                    setIsLoading(false)
                    setUsername((!!res.firstname? res.firstname: '')+' '+(!!res.lastname? res.lastname: ''))
                    setStatusData(res)
                    // history.push('/')

                    // localStorage.removeItem('authData')
                }
            })
            .catch(err => {
                console.log(err);
                history.push('/')
                // setIsLoading(false)
            });
        }

        if(!!localStorage.getItem('authLocation')) {
            fetchStatus()
        } else {
            history.push('/')
        }


        return ()=> { unamounted = true }

    }, [apiXKey, history])

    const fetchLogout =  async() => {
        // console.log(apiXKey)
        // console.log(authDataObj.token)
        await fetch(`https://${apiLocation}/ecp/api/vsm/logout`, {
            method: 'POST',
            // body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        history.push('/')
        // localStorage.removeItem('authData')

    }

    const closeModal = () => {
        setModalLogout(false)
    }

    const handleSelect = (index) => {
        setSelectedIndex(index)
    }

    const handleButtonClick = () => {
        setSelectedIndex(0)
    }

    Modal.setAppElement('#root');

    return (
        <React.Fragment>
            {
                isLoading ? 
                <div style={{position: 'relative', width: '100vw', height: '100vh', backgroundColor: '#878b8e'}}>
                    <p style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', margin: 'auto', color: '#fff'}}>...Loading...</p>
                </div>
                :
                <div className="home-page">
                    <div className="header">
                        <div className="left-side">
                            <div className="logo">
                                <img src={IconHeader} alt="icon"/> PMI
                            </div>
                            <button  onClick={()=>setCollapseMenu(!collapseMenu)}>
                                {
                                    collapseMenu ? <img style={{width: '30px', height: '25px', marginTop: '5px'}} src={IconMenu} alt="icon" /> : <span style={{color: 'white',marginRight: '5px'}}>X</span>
                                }
                            </button>
                        </div>
                        <div className="right-side">
                            <div className="logout" onClick={()=>{setModalLogout(true)}}>
                                ออกจากระบบ
                            </div>
                            <div className="username">
                                <p>{ username }</p>
                            </div>
                            <div className="avatar">
                                <img src={IconProfile} alt="icon"/>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <Tabs selectedIndex={selectedIndex} onSelect={handleSelect}>
                            <div className={`left-side ${collapseMenu? 'collapse': ''}`}>
                                <TabList>
                                    <Tab>
                                        <img src={IconShop} alt="icon"/> ร้านค้า
                                    </Tab>
                                    <Tab>
                                        <img src={IconReport} alt="icon"/> รายงาน
                                    </Tab>
                                    <Tab>
                                        <img src={IconStats} alt="icon"/> สถิติ
                                    </Tab>
                                </TabList>
                            </div>
                            <div className={`right-side ${collapseMenu? 'expand': ''}`}>
                                <TabPanel className="react-tabs__tab-panel">
                                    <Shop />
                                </TabPanel>
                                <TabPanel >
                                    {/* <button onClick={()=>handleButtonClick()}>Finish</button> */}
                                    <Report onClick={handleButtonClick} />
                                </TabPanel>
                                <TabPanel >
                                    {/* <button onClick={()=>handleButtonClick()}>Finish</button> */}
                                    <Statistic statusData={statusData} onClick={handleButtonClick} />
                                </TabPanel>
                            </div>
                            
                        </Tabs>
                    </div>
                    
                </div>
            }

            <Modal 
                isOpen={modalLogout} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
                <div className="_error-modal center">
                    <p>ต้องการออกจากระบบ ?</p>
                    <br/>
                    <ButtonRound text="ไม่ออก" color="grey" onClick={closeModal}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <ButtonRound text="ออก" color="blue" onClick={fetchLogout}/>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Home
