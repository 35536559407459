import React from 'react'
import Select from 'react-select'

import IconCheck from './../assets/img/ic-check.svg'

const customStyles = {
  groupHeading: (provided, state) => ({
      ...provided,
    color: state.isSelected ? 'red' : 'black',
    fontSize: '1.6rem',
    textIndent: 30,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'gray' : 'gray',
    backgroundImage:  state.isSelected ? `url(${IconCheck})` : null,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '15px',
    backgroundPosition: '50px center',
    backgroundColor: 'white',
    // textIndent: 20,
    fontSize: '1.4rem',
    padding: '2px 12px'
  }),
}

function ColumnMultipleFilter(props) {
    const { preDataUserDetail, onChanged } = props
    
    // Get data array
    let dataArr = []
    preDataUserDetail.map((item)=> 
        dataArr.push({
            value: item.campaign_name,
            label: item.campaign_name,
        })
    )

    // Get obj array to new array [{}] => []
    let singleDataArr = []
    for(let z=0; z<dataArr.length; z++) {
      singleDataArr.push(dataArr[z].value)
    }
    const onlyUnique = (value, index, self) => {
      return self.indexOf(value) === index;
    }
    let unique = singleDataArr.filter(onlyUnique);
    // console.log(unique)
    
    // Get final data array
    let finalDataArr = []
    for(let y=0; y<unique.length; y++) {
      finalDataArr.push({
        value: unique[y],
        label: unique[y],
      })
    }

    return (
        <Select
            closeMenuOnSelect={true}
            isMulti
            options={finalDataArr} // options={dataArr}
            styles={customStyles}
            onChange={(e)=>{onChanged(e)}}
         />

        // <div>
        //     {/* search: {' '} */}
        //     {/* <input value={filterValue || ''} onChange={(e)=>setFilter(e.target.value)} /> */}
        //     <select multiple className="input-filter-status" value={filterValue || ""} onChange={e => {setFilter(e.target.value); /*console.log('search',e.target.value)*/}}>
        //         {
        //             column.preFilteredRows.map((item)=> {
        //                 return (
        //                     <option key={item.values.ecp_id} value={item.values.ecp_id}>{item.values.ecp_id}</option>
        //                 )
        //             })
        //         }
                
        //     </select>
        // </div>
    )
}

export default ColumnMultipleFilter
