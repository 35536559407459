import React, {useState} from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Home';
import Login from './Login';

import AuthContext from '../components/AuthContext';

function Main() {
    const [authData, setAuthData] = useState(null)

    return (
        <AuthContext.Provider value={{authData, setAuthData}}>
            <BrowserRouter>
                <Route exact path="/" render={()=> <Login /> } />
                <Route path="/home" render={()=> <Home />} />
            </BrowserRouter>
        </AuthContext.Provider>
        
    )
}

export default Main
