import React from 'react'
import { useTable,useSortBy,usePagination,useGlobalFilter } from 'react-table'

function Table({ columns, data, showPagination, isLoading, colspan, rowProps = () => ({})  }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      // state,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      // pageCount,
      // gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter}
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
    )
    
    return (
      <>
        {/* <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2
            )}
          </code>
        </pre> */}
        <input
          type="text"
          value={globalFilter || ""}
          onChange={e => setGlobalFilter(e.target.value)}
          className="input-search" 
          placeholder="Search"
        />
        <div style={{overflow: 'auto'}}>
          <table {...getTableProps()} border="1">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    }))}>
                      {column.render('Header')}
                      <span style={{fontSize: '0.5rem'}}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ▲'
                          : ' ▼'
                        : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                  isLoading ?
                  <tr>
                    <td style={{padding: '80px'}} colSpan={colspan}>...Loading...</td>
                  </tr>
                  :
                  page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps(rowProps(row))}>
                        {row.cells.map(cell => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                  })
              }
            </tbody>
          </table>
        </div>

      {
        showPagination ? 
        <div className="pagination mg-t-10">
          <div className="pagination-left">
            <span>Rows per page:</span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 15, 20, 25, 30].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>

          <div className="pagination-right">
            {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            {' '} */}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <span>
              Page{' '}
              {/* <strong> */}
                {pageIndex + 1} of {pageOptions.length}
              {/* </strong> */}
              {' '}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            {/* {' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '} */}
          </div>
          
          {/* <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '} */}

          
        </div>
        :
        null
      }
      </>
    )
}

export default Table
