import React, {useState, useEffect} from 'react'
// import { useHistory } from 'react-router-dom';
import { useTable,useSortBy,useGroupBy,usePagination,useRowSelect,useGlobalFilter,useFilters } from 'react-table'
import Modal from 'react-modal'

// import ColumnMultipleFilter from '../components/ColumnMultipleFilter'
import ButtonRound from '../components/ButtonRound';

let apiXKey = localStorage.getItem('authXkey')

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

function TableRowSelectGroupBy({/*preDataUserDetail, onChanged,*/ columns, data, showPagination, isLoading, colspan, rowProps = () => ({})  }) {
    // const history = useHistory();
    const [msgError, setMsgError] = useState('ไม่สามารถทำรายการได้')
    const [modalError, setModalError] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalCheckSummary, setModalCheckSummary] = useState(false)
    const [qrCodeUniqDontMatch, setQrCodeUniqDontMatch] = useState([])
    const [selectCouponConfirm, setSelectCouponConfirm] = useState({
      ecp_id: [],
      total_ecp: 0,
      total_face_value: 0,
      total_topup_value: 0,
      total_all_value: 0
    })
    const [btnActive, setBtnActive] = useState(false)
    // const [totalFaceValue, setTotalFaceValue] = useState(0)
    // const [totalData, setTotalData] = useState({
    //   totalEcp: 0,
    //   totalFaceValue: 0,
    //   totalTopupValue: 0,
    //   totalAllValue: 0,
    // })

    // Check summary
    const checkSummary = (val) => {
      val = val.filter((item)=>item.values.ecp_checkbox)
      
      let ecpIdArr = []
      let qrcodeDupArr = []
      let ecpDupArr = []

      for(let i=0; i<val.length; i++) {
        // console.log(val[i].original.ecp_id)
        ecpIdArr.push(val[i].original.ecp_id)
        // console.log(val[0].values.qrcode)
        // console.log('selectCouponList',i,':',ecpIdArr.length)
        if(val[i].values.qrcode !== null) {
          for(let j=0; j<data.length; j++){
            if(val[i].values.qrcode === data[j].qrcode && data[j].ecp_checkbox) {
              qrcodeDupArr.push(data[j].qrcode)
              ecpDupArr.push(data[j].ecp_id)
            }
          }
        } else {
          // [Send] list QR Code null -------------------------------------------//
          // ecpDupArr.push(val[i].values.ecp_id)
          for(let j=0; j<data.length; j++){
            if(val[i].values.qrcode === data[j].qrcode && data[j].ecp_checkbox) {
              ecpDupArr.push(data[j].ecp_id)
            }
          }
        }
    
        // console.log('-------------------------------------------')
      }

      // let qrcodeUniq = qrcodeDupArr.reduce(function(a,b){
      //   if (a.indexOf(b) < 0 ) a.push(b);
      //   return a;
      // },[]);

      // [Find] list QR Code uniq -------------------------------------------//
      let ecpIdUniq = ecpDupArr.reduce(function(a,b){
        if (a.indexOf(b) < 0 ) a.push(b);
        return a;
      },[]);
    

      // console.log('qrcodeUniq',qrcodeUniq)
      console.warn('ecpIdArrSelectd',ecpIdArr)
      console.log('ecpIdUniqList',ecpIdUniq)


      if(ecpIdArr.length === ecpIdUniq.length || !qrcodeDupArr.length) {
        // [Send] list QR Code match -------------------------------------------//
        setModalCheckSummary(false)
        checkCoupons(ecpIdArr)
      } else {
        const ecpDontMatch = ecpIdUniq.filter(e => !ecpIdArr.includes(e));
        // console.log('ecpDontMatch',ecpDontMatch)

        // [Add] list QR Code don't match -------------------------------------------//
        let qrcodeDontMatch = []
        for(let i=0; i<ecpDontMatch.length; i++) {
          for(let k=0; k<data.length; k++) {
            if(ecpDontMatch[i] === data[k].ecp_id) {
              // console.log('ecpIdDontMatch',data[k].ecp_id,',',ecpDontMatch[i] )
              qrcodeDontMatch.push(data[k].qrcode)
            }
          }
        }
        
        // console.log('qrcodeDontMatch',qrcodeDontMatch)
        // [Compare] list QR Code don't match -------------------------------------------//
        let qrcodeDontMatchArr = qrcodeDontMatch.reduce(function(a,b){
          if (a.indexOf(b) < 0 ) a.push(b);
          return a;
        },[]);
        // [Alert] list QR Code don't match -------------------------------------------//
        // console.warn('qrcodeDontMatchArr',qrcodeDontMatchArr)
        setQrCodeUniqDontMatch(qrcodeDontMatchArr)
        setModalCheckSummary(true)
      }
    }

    const checkCoupons = async (ecpIdArr) => {
      // console.log(val.isSelected)
      // console.log(localStorage.getItem('authToken'))
      
      const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/check_coupons/`+localStorage.getItem('userDetailId'), {
          method: 'POST',  
          body: JSON.stringify({
            "ecp_id": ecpIdArr
          }),
          headers: {
              "x-application-secret-key": apiXKey,
              "Authorization": "Bearer "+localStorage.getItem('authToken'),
              "Content-Type": "application/json",
              "Accept": "application/json"
          }
      });

      res
      .json()
      .then(res => {
            // setIsLoading(true)
            console.log('data checkSummary:',res)
            if(res.success ===0) {
              setMsgError(res.message)
              setModalError(true)
            } else {
              setModalConfirm(true)
              setSelectCouponConfirm({
                ecp_id: !!res.ecp_id?res.ecp_id:[],
                total_ecp: !!res.total_ecp?res.total_ecp:0,
                total_face_value: !!res.total_face_value?res.total_face_value:0,
                total_topup_value: !!res.total_topup_value?res.total_topup_value:0,
                total_all_value: !!res.total_all_value?res.total_all_value:0,
              })
            }
      })
      .catch(err => {
          console.log(err);
          window.location.href="/"
      });
    }

    // Confirm coupon
    const fetchSelectCouponConfirm =  async() => {
      // setIsLoading(false)
      console.log(JSON.stringify(selectCouponConfirm))

      if(selectCouponConfirm) {
      
        const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/select_coupons/`+localStorage.getItem('userDetailId'), {
            method: 'POST',  
            body: JSON.stringify(selectCouponConfirm),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
  
        res
        .json()
        .then(res => {
              // setIsLoading(true)
              console.log('select_coupon:',res)
              setModalConfirm(false)
              if(res.success===0) {
                setModalError(true)
              } else {
                // history.push('/home')
                window.location.reload()
              }
        })
        .catch(err => {
            console.log(err);
            // window.location.href="/"
            setModalError(true)
        });

      } else {
        console.log(msgError)
      }
    }

    

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      // setFilter,
      selectedFlatRows,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      // pageCount,
      // gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      toggleAllRowsSelected,
      state: { pageIndex, pageSize, globalFilter, /*filterValue,selectedRowIds*/}
    } = useTable({
        columns,
        data,
        initialState: { 
          pageIndex: 0, 
          hiddenColumns: ['ecp_checkbox'], 
          sortBy: [
            {
              id: "end_date",
              desc: true
            }
          ],
          filters: [
            {
              id: "coupon_status",
              value: "พร้อมสแกน"
            }
          ] 
        },
      },
      useGlobalFilter,
      useFilters,
      useGroupBy,
      useSortBy,
      usePagination,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => { 
              // console.log(row)
              if(row.values.ecp_checkbox) {
                return (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                )
              } else {
                return false
              }
              
            },
          },
          ...columns,
        ])
      }
    )

    useEffect(() => {
      // Check amount rows have checkbox on selected 
      let rowsHaveCheckbox = selectedFlatRows.filter((item)=>item.values.ecp_checkbox)
      // console.log('selected all rows:',selectedFlatRows)
      // console.log('selected have checkbox rows:',rowsHaveCheckbox)

      if(rowsHaveCheckbox.length) {
        setBtnActive(true)
      } else {
        setBtnActive(false)
      }

    }, [selectedFlatRows])
    
    const resetCheckBox = () => {
      toggleAllRowsSelected(false)
      console.log('reset all coupon')
    }

    Modal.setAppElement('#root');
    
    return (
      <>
        {/* <pre>
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
              },
              null,
              2
            )}
          </code>
        </pre> */}
        {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
        <pre>
          <code>
            {JSON.stringify(
              {
                selectedRowIds: selectedRowIds,
                'selectedFlatRows[].original': selectedFlatRows.map(
                  d => d.original.ecp_id
                ),
              },
              null,
              2
            )}
          </code>
        </pre> */}
        <input
          type="text"
          value={globalFilter || ""}
          onChange={e => {setGlobalFilter(e.target.value); console.log('search',e.target.value)}}
          className="input-search" 
          placeholder="Search"
        />
        
        {/* <div style={{textAlign: 'right', width: '100%'}}>
        
            <select id="coupon_status" className="input-filter-status" value={filter}
              onChange={e => {setFilter(e.target.value); console.log('search',e.target.value)}}>
                <optgroup>
              <option value="">แสดงทุกสถานะ</option>
              <option value="พร้อมสแกน">พร้อมสแกน</option>
              <option value="พร้อมสแกน">รอแลกคืน</option>
              <option value="กำลังแลกคืน (ไม่สแกน)">กำลังแลกคืน (ไม่สแกน)</option>
              <option value="กำลังแลกคืน">กำลังแลกคืน</option>
              <option value="แลกคืนแล้ว">แลกคืนแล้ว</option>
              <option value="หมดอายุแล้ว">หมดอายุแล้ว</option>
              </optgroup>
            </select> 
          </div> */}
        <div style={{overflow: 'auto'}}>

          
          <table {...getTableProps()} border="1" className="table-ecp-detail">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps({
                        style: { minWidth: column.minWidth, width: column.width },
                      }))}>
                      {column.render('Header')}
                      <span style={{fontSize: '0.5rem'}}>
                        {
                        column.isSorted
                          ? column.isSortedDesc
                            ? ' ▲'
                            : ' ▼'
                          : ''}
                      </span>
                      <div>
                      {
                          // console.log(column)
                          (column.canFilter && column.id === 'coupon_status')  ?
                            column.render('Filter')
                          //   <select id="coupon_status" className="input-filter-status" value={filterValue}
                          //   onChange={e => {setFilter(e.target.value); console.log('search',e.target.value)}}>
                          //     <optgroup>
                          //       <option value="">แสดงทุกสถานะ</option>
                          //       <option value="พร้อมสแกน">พร้อมสแกน</option>
                          //       <option value="พร้อมสแกน">รอแลกคืน</option>
                          //       <option value="กำลังแลกคืน (ไม่สแกน)">กำลังแลกคืน (ไม่สแกน)</option>
                          //       <option value="กำลังแลกคืน">กำลังแลกคืน</option>
                          //       <option value="แลกคืนแล้ว">แลกคืนแล้ว</option>
                          //       <option value="หมดอายุแล้ว">หมดอายุแล้ว</option>
                          //     </optgroup>
                          // </select>
                          // : 
                          // (column.canFilter && column.id === 'ecp_id') ?
                          //    <ColumnMultipleFilter preDataUserDetail={preDataUserDetail} onChanged={onChanged} column={column} />

                          : null
                        }</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              
              {
                isLoading ?
                <tr>
                  <td style={{padding: '80px'}} colSpan={colspan}>...Loading...</td>
                </tr>
                :
                page.map((row, i) => {
                  prepareRow(row)
                  // console.log(row)
                  return (
                    <tr {...row.getRowProps(rowProps(row))}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      {
        showPagination ? 
        <div className="pagination mg-t-10">
          <div className="pagination-left">
            <span>Rows per page:</span>
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 15, 20, 25, 30].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>

          <div className="pagination-right">
            {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>
            {' '} */}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <span>
              Page{' '}
              {/* <strong> */}
                {pageIndex + 1} of {pageOptions.length}
              {/* </strong> */}
              {' '}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            {/* {' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '} */}
          </div>
          
          {/* <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '} */}

          
        </div>
        :
        null
      }
      
        {
          // selectedFlatRows.length !== 0 ? 
          btnActive ?
          <div className="btn-tableRowSelect">
            <ButtonRound text="ยกเลิก" color="grey" onClick={()=>{resetCheckBox()}}/>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ButtonRound text="แลกคืน" color="blue" onClick={()=>{checkSummary(selectedFlatRows)}}/>
          </div>
          : null
        }
        

        <Modal 
              isOpen={modalConfirm} 
              className="_modal box-style modal-scanqrcode" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_error-modal center">
                  <p>ตกลงที่จะเสนอรับคูปอง {!!selectCouponConfirm.total_ecp?selectCouponConfirm.total_ecp.toLocaleString():0} ใบ <br/> แลกกับส่วนลด {!!selectCouponConfirm.total_all_value?selectCouponConfirm.total_all_value.toLocaleString():0} บาทให้กับร้านค้า</p>
                  <br/>
                  <ButtonRound text="ยกเลิก" color="grey" onClick={()=>{setModalConfirm(false)}}/>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <ButtonRound text="ตกลง" color="blue" onClick={()=>{fetchSelectCouponConfirm()}}/>
              </div>
          </Modal>

          <Modal 
              isOpen={modalError} 
              className="_modal box-style modal-scanqrcode" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_error-modal center">
                  <p>{msgError}</p>
                  <br/>
                  <ButtonRound text="ตกลง" color="blue" onClick={()=>{setModalError(false)}}/>
              </div>
          </Modal>

          <Modal 
              isOpen={modalCheckSummary} 
              className="_modal box-style modal-scanqrcode" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_error-modal center">
                  <p>มีคูปองใน QR { 
                      qrCodeUniqDontMatch.map((item,i)=> { 
                        if(qrCodeUniqDontMatch.length === i+1) {
                          return (<span key={i}>{item}&nbsp;</span>)
                        } else {
                          return (<span key={i}>{item},&nbsp;</span>)
                        }
                      })
                    }  ที่ยังไม่ได้ถูกเลือก กรุณาเลือกให้ครบ</p>
                  <br/>
                  <ButtonRound text="ปิด" color="blue" onClick={()=>{setModalCheckSummary(false)}}/>
              </div>
          </Modal>
      </>
    )
}

export default TableRowSelectGroupBy
