import React, { useEffect, useState } from 'react'
// import TableNoPagination from '../components/TableNoPagination'
import Modal from 'react-modal'
import moment from 'moment'
import { DateRange } from 'react-date-range';

import ButtonRound from '../components/ButtonRound';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import IconCalendar from './../assets/img/ic-calendar.png'
import IconXls from './../assets/img/ic-xls.png'


let apiXKey = localStorage.getItem('authXkey')
// let descData = 1
let descDataArr = {
    ts: 1,
    qrcode: 0,
    cp_id: 0,
    ecp_id: 0,
    amount: 0,
    action: 0,
    minimum_purchase: 0,
    giver_isms: 0,
    giver_name: 0,
    receiver_isms: 0,
    receiver_name: 0,
}

function Report(props) {
    const { onClick } = props
    // const columns = React.useMemo(
    //     () => [
    //         {
    //             Header: "เวลา",
    //             accessor: "ts",
    //             minWidth: 140,
    //             width: 200,
    //             Cell: (row) => {
    //                 return <div>{moment(row.value).format('DD MMM YYYY HH:mm')==='Invalid date'?'':moment(row.value).format('DD MMM YYYY HH:mm')}</div>;
    //             },
    //         },
    //         {
    //             Header: 'รหัส QR',
    //             accessor: 'qrcode',
    //             minWidth: 100,
    //         },
    //         {
    //             Header: 'รหัสคูปอง',
    //             accessor: 'ecp_id',
    //             minWidth: 140,
    //         },
    //         {
    //             Header: 'มูลค่า',
    //             accessor: 'amount',
    //             minWidth: 140,
    //         },
    //         {
    //             Header: 'เหตุการณ์',
    //             accessor: 'action',
    //             minWidth: 120,
    //         },
    //         {
    //             Header: 'ผู้ให้ ISMS',
    //             accessor: 'giver_isms',
    //             minWidth: 120,
    //         },
    //         {
    //             Header: 'ชื่อผู้ให้',
    //             accessor: 'giver_name',
    //             minWidth: 120,
    //         },
            
    //         {
    //             Header: 'ผู้รับ ISMS',
    //             accessor: 'receiver_isms',
    //             minWidth: 120,
    //         },
    //         {
    //             Header: 'ชื่อผู้รับ',
    //             accessor: 'receiver_name',
    //             minWidth: 120,
    //         },
    //   ],[]
    // )
    const [isLoading, setIsLoading] = useState(false)
    const [errModal, setErrModal] = useState(false)
    const [msg] = useState('เกิดข้อผิดพลาด กรุณาลองอีกครั้ง')
    const [modalDatePickerRange, setModalDatePickerRange] = useState(false)
    const [dataReport, setDataReport] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [pageTotal, setPageTotal] = useState(0)
    const [startValue, setStartValue] = useState(0)
    const [searchData, setSearchData] = useState('')
    const [orderData, setOrderData] = useState('ts')
    // const [pageSelect, setPageSelect] = useState(0)
    // const [descData, setDescData] = useState(0)
    // const [descDataArr, setDescDataArr] = useState({
    //     ts: 0,
    //     qrcode: 0,
    //     cp_id: 0,
    //     ecp_id: 0,
    //     amount: 0,
    //     action: 0,
    //     giver_isms: 0,
    //     giver_name: 0,
    //     receiver_isms: 0,
    //     receiver_name: 0,
    // })
    const [exportActive, setExportActive] = useState(false)
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          isShowCalendar: false,
        }
      ]);

    useEffect(() => {
        
        fetchReport(
            moment(state[0].startDate).format('YYYY-MM-DD'),
            moment(state[0].endDate).format('YYYY-MM-DD'),
            orderData,
            descDataArr['ts'],
            startValue,
            pageSize,
            searchData
        )
  
        return ()=> { }
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [/*state*/])

    const fetchReport =  async(df,dt,od,ds,st,ct,sc) => {
        setExportActive(false)
        setIsLoading(true)
        const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/local/vsm/vsmcampaignreport?from=${df}&to=${dt}&order_by=${od}&desc=${ds}&start=${st}&count=${ct}&search=${sc}`, {
            method: 'GET',  
            // body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
  
        res
        .json()
        .then(res => {
              setIsLoading(false)
            //   console.log('data report:',res.data)
              setDataReport(res.data)
              setPageTotal(res.total_records)
            //   console.log('res report length:',res.data.length)
              if(res.data.length > 0) {
                setExportActive(true)
              }
            //   console.log('startValue',startValue,', pageSize',pageSize,' pageTotal',pageTotal)
        })
        .catch(err => {
              setIsLoading(true)
              console.log(err);
              setErrModal(true)
            //   window.location.href="/"
        });
    }

    const fetchExportExcel =  async(df,dt,st,ct) => {
        // Set inactive button Export to Excel
        setExportActive(false)

        // setIsLoading(false)
        fetch(`https://${localStorage.getItem('authLocation')}/ecp/local/vsm/vsmcampaignreport/download?from=${df}&to=${dt}&order_by=ts&desc=1&start=${st}&count=${ct}`, {
            method: 'GET',
            headers: {
              "x-application-secret-key": apiXKey,
              "Authorization": "Bearer "+localStorage.getItem('authToken'),
              'Content-Type': '*/*',
            },
          })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `transactions_report_from_${moment(df).format('DDMMMYYYY')}_to_${moment(dt).format('DDMMMYYYY')}.xlsx`,
            );
  
            // Append to html link element page
            document.body.appendChild(link);
  
            // Start download
            link.click();
  
            // Clean up and remove the link
            link.parentNode.removeChild(link);

            // Set active button Export to Excel (delay 2 sec)
            setTimeout(() => {
                setExportActive(true)
            }, 2000);
          });
      }

    const previousPage = () => {
        setStartValue(startValue - pageSize)
        fetchReport(
            moment(state[0].startDate).format('YYYY-MM-DD'),
            moment(state[0].endDate).format('YYYY-MM-DD'),
            orderData,
            descDataArr[orderData],
            (startValue - pageSize),
            pageSize,
            searchData
        )
    }

    const nextPage = () => {
        setStartValue(startValue + pageSize)
        fetchReport(
            moment(state[0].startDate).format('YYYY-MM-DD'),
            moment(state[0].endDate).format('YYYY-MM-DD'),
            orderData,
            descDataArr[orderData],
            (startValue + pageSize),
            pageSize,
            searchData
        )

        // console.warn(orderData, descDataArr[orderData])
    }

    const handleOrderBy = (valOrderBy) => {
            setOrderData(valOrderBy)
            setStartValue(0)
            // Toggle desc value
            let descValue = !descDataArr[valOrderBy]
            // setDescDataArr({
            //     ...descDataArr,
            //     [valOrderBy]: descValue?1:0
            // })
            descDataArr[valOrderBy] = descValue?1:0
            
            fetchReport(
                moment(state[0].startDate).format('YYYY-MM-DD'),
                moment(state[0].endDate).format('YYYY-MM-DD'),
                valOrderBy,
                descDataArr[valOrderBy],
                0,
                pageSize,
                searchData,
            )
        
            // console.log(descDataArr)
            // console.warn(orderData, descDataArr[orderData])
    }

    const handleSearch = (valSearch) => {
        setSearchData(valSearch)
        fetchReport(
            moment(state[0].startDate).format('YYYY-MM-DD'),
            moment(state[0].endDate).format('YYYY-MM-DD'),
            orderData,
            descDataArr['ts'],
            startValue,
            pageSize,
            valSearch,
        )
    }

    const handleClickOpenCalendar = () => {
        // setState({
        //   isShowCalendar: true
        // });
        setModalDatePickerRange(true)
    };

    const handlePageSelect = (e) => {
        // setPageSelect(e.target.value)
        console.log('pageSelect',e.target.value,'* PageSize:',e.target.value*pageSize)
        setStartValue(e.target.value * pageSize)
        fetchReport(
            moment(state[0].startDate).format('YYYY-MM-DD'),
            moment(state[0].endDate).format('YYYY-MM-DD'),
            orderData,
            descDataArr[orderData],
            (e.target.value * pageSize),
            pageSize,
            searchData
        )
    }

    const genOption = (totalPage, currentPage) => {
        let optionArr = []

        for(let i=0; i<totalPage; i++) {
            optionArr.push(i+1)
        }

        return (
            <select value={currentPage} onChange={handlePageSelect}>
                {optionArr.map((item,i) => (
                    <option key={i} value={i}>
                    {item}
                    </option>
                ))}
            </select>
        )
    }

    return (
        <div className="report-content">
            <div className="header-content">
                <div className="left-content">
                    <h1>Report</h1>
                    <p><span className="font-blue" onClick={onClick}>HOME</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;REPORT</p>
                </div>
                <div className="right-content">
                    <input
                        type="text"
                        className="input-date" 
                        onClick={()=>{handleClickOpenCalendar()}}
                        readOnly
                        value={moment(state[0].startDate).format('DD MMM YYYY')+' - '+moment(state[0].endDate).format('DD MMM YYYY') }
                    />

                  <button className="btn-calendar"  type="button" onClick={()=>{handleClickOpenCalendar()}}>
                    <img src={IconCalendar} alt="reload" />
                  </button>
                </div>
            </div>

            <div className="mg-t-30">
                <input
                    type="text"
                    // value={globalFilter || ""}
                    value={searchData}
                    onChange={e=> handleSearch(e.target.value)}
                    // onChange={e => setGlobalFilter(e.target.value)}
                    className="input-search-report" 
                    placeholder="Search"
                />
                {
                    exportActive ? 
                    <button className="btn-xls"  type="button" onClick={()=>{fetchExportExcel(moment(state[0].startDate).format('YYYY-MM-DD'),moment(state[0].endDate).format('YYYY-MM-DD'),startValue,pageSize)}}>
                    <img src={IconXls} alt="reload" />
                    <span>Export to Excel</span>
                    </button>
                    :

                    <button className="btn-xls"  type="button" style={{opacity: '0.25'}}>
                    <img src={IconXls} alt="reload" />
                    <span>...Exporting...</span>
                    </button>
                }
            </div>

            <div className="mg-t-0">
                {/* <TableNoPagination 
                    columns={columns} 
                    data={dataReport} 
                    df={moment(state[0].startDate).format('YYYY-MM-DD')} 
                    dt={moment(state[0].endDate).format('YYYY-MM-DD')}
                    st={startValue}
                    ct={pageSize} 
                    exportActive={exportActive}
                /> */}
                <div style={{overflow: 'auto'}}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('ts')}>เวลา 
                                    { orderData === 'ts' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.ts ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '100px'}} onClick={()=>handleOrderBy('qrcode')}>รหัส QR
                                    { orderData === 'qrcode' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.qrcode ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('ecp_id')}>รหัสคูปอง
                                    { orderData === 'ecp_id' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.ecp_id ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('amount')}>มูลค่า
                                    { orderData === 'amount' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.amount ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '120px'}} onClick={()=>handleOrderBy('action')}>เหตุการณ์
                                    { orderData === 'action' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.action ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '120px'}} onClick={()=>handleOrderBy('minimum_purchase')}>ซื้อขั้นต่ำ (แถว)
                                    { orderData === 'minimum_purchase' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.minimum_purchase ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('giver_isms')}>ผู้ให้ ISMS
                                    { orderData === 'giver_isms' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.giver_isms ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('giver_name')}>ชื่อผู้ให้
                                    { orderData === 'giver_name' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.giver_name ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('receiver_isms')}>ผู้รับ ISMS
                                    { orderData === 'receiver_isms' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.receiver_isms ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('receiver_name')}>ชื่อผู้รับ
                                    { orderData === 'receiver_name' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.receiver_name ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        isLoading ?
                        <tr>
                            <td style={{padding: '80px'}} colSpan={9}>...Loading...</td>
                        </tr>
                        :
                        dataReport.length ?
                            dataReport.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{moment(row.ts).format('DD MMM YYYY HH:mm')==='Invalid date'?'':moment(row.ts).format('DD MMM YYYY HH:mm')}</td>
                                        <td>{row.qrcode}</td>
                                        <td>{row.ecp_id}</td>
                                        <td>{row.amount}</td>
                                        <td>{row.action}</td>
                                        <td>{row.minimum_purchase}</td>
                                        <td>{row.giver_isms}</td>
                                        <td>{row.giver_name}</td>
                                        <td>{row.receiver_isms}</td>
                                        <td>{row.receiver_name}</td>
                                    </tr>
                                )
                            })
                        : <tr><td colSpan={10}>No Data</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className="pagination mg-t-10">
                    <div className="pagination-left">

                        <span>Rows per page:</span>
                        <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                            setStartValue(0)
                            fetchReport(
                                moment(state[0].startDate).format('YYYY-MM-DD'),
                                moment(state[0].endDate).format('YYYY-MM-DD'),
                                orderData,
                                descDataArr['ts'],
                                0,
                                Number(e.target.value),
                                searchData
                            )
                        }}
                        >
                        {[10, 15, 20, 25, 30].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            {pageSize}
                            </option>
                        ))}
                        </select>

                    </div>
                    <div className="pagination-right">
                        <button onClick={() => previousPage()}  disabled={(startValue/pageSize)+1 === 1}>
                            {'<'}
                        </button>{' '}
                        <span>
                            Page{' '}
                            {/* <strong> */}
                                {/* {Math.ceil((startValue/pageSize)+1)} of {Math.ceil(pageTotal/pageSize)} */}
                            {/* </strong> */}
                            
                            {
                                genOption(Math.ceil(pageTotal/pageSize), Math.ceil((startValue/pageSize)))
                            }

                            of {Math.ceil(pageTotal/pageSize)}
                            {' '}
                        </span>
                        <button onClick={() => nextPage()}  disabled={(startValue/pageSize)+1 === Math.ceil(pageTotal/pageSize)}>
                            {'>'}
                        </button>
                    </div>
                </div>
            </div>

            <Modal 
              isOpen={modalDatePickerRange} 
              className="_modal box-style modal-date" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_date-modal center">
                  <DateRange
                        editableDateInputs={false}
                        onChange={item => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        showDateDisplay={false}
                        dateDisplayFormat="DD MMM YYYY"
                    />
                  <br/>
                  <ButtonRound text="ตกลง" color="blue" onClick={()=>{
                    setModalDatePickerRange(false); 
                    // console.log(state[0].startDate);  
                    // console.log(state[0].endDate);
                    fetchReport(
                        moment(state[0].startDate).format('YYYY-MM-DD'),
                        moment(state[0].endDate).format('YYYY-MM-DD'),
                        orderData,
                        descDataArr['ts'],
                        startValue,
                        pageSize,
                        searchData
                    )
                  }}/>
                    
              </div>
          </Modal>

          <Modal 
              isOpen={errModal} 
              className="_modal box-style modal-date" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_date-modal center" style={{margin: '20px 10px 10px'}}>

                  <h3 style={{lineHeight: '1'}}>{msg}</h3>
                  <br/>
                  <ButtonRound text="ตกลง" color="blue" onClick={()=>{setErrModal(false); setIsLoading(false); /*console.log(state[0].startDate);  console.log(state[0].endDate);*/}}/>
                    
              </div>
          </Modal>
            
        </div>
    )
}

export default Report
