import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Modal from 'react-modal'
import moment from 'moment'

import ButtonRound from '../components/ButtonRound';
import IconXls from './../assets/img/ic-xls.png'
import IconCheck from './../assets/img/ic-check.svg'
import IconDrillup from './../assets/img/ic-drillup.svg'
import IconLoading from './../assets/img/ic-loading.svg'

import mockupdata from '../data_drill_0.json'
// import { chart } from 'highcharts';

let apiXKey = localStorage.getItem('authXkey')
let descDataArr = { // &desc=
    ecp_id: 0,
    face_value: 0,
    topup: 0,
    minimum_purchase: 0,
    end_date: 0,
    ecp_status: 0,
    isms_code: 0,
    shop_name: 0,
    group_type: 0,
    role: 1,
}

const customStyles = {
    groupHeading: (provided, state) => ({
        ...provided,
      color: state.isSelected ? 'red' : 'black',
      fontSize: '1.6rem',
      textIndent: 30,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'gray' : 'gray',
      backgroundImage:  state.isSelected ? `url(${IconCheck})` : null,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '15px',
      backgroundPosition: '50px center',
      backgroundColor: 'white',
      textIndent: 60,
      fontSize: '1.4rem',
      padding: '2px 12px'
    }),
}

let couponStatusArr = [
    'used',
    'uncollected',
    'cannot-collect',
    'uncollected-point',
    'cannot-collect-point',
    'unused',
    'expired',
    'waiting-to-redeem',
    'waiting-for-confirmation',
    'redeemed'
]

let fetchData = {
    cp_id: '',
    app_id: '',
    drill: 0,
    coupon_status: couponStatusArr,
    asm_code: '',
    rsm_code: '',
    tse_code: '',
    order_by: 'role',
    desc: 1,
    start: 0,
    count: 20,
}

let showAsPercentage = false
let isChartLoading = false

let lv = 0
let lvRole = 0
let roleArr = []
let roleName = ['SA','RSM','ASM','TSE']

function Statistic(props) {
    const {statusData, onClick} = props

    const [isLoading, setIsLoading] = useState(false)
    const [errModal, setErrModal] = useState(false)
    const [msg] = useState('เกิดข้อผิดพลาด กรุณาลองอีกครั้ง')
    const [dataTable, setDataTable] = useState([])
    const [pageSize, setPageSize] = useState(5) // &count=
    const [pageTotal, setPageTotal] = useState(0)
    const [startValue, setStartValue] = useState(0) // &start=
    const [orderData, setOrderData] = useState('role') // &order_by=

    const [optionList, setOptionList] = useState([])
    const [checkboxOption, setCheckboxOption] = useState({
        cp_id: false,
        app_id: false
    })
    const [checkboxPercentage, setCheckboxPercentage] = useState(false)
    const [value, setValue] = useState([]);
    const [appChartResultData, setAppChartResultData] = useState({})
    const [btnDrillActive, setBtnDrillActive] = useState(false)

    useEffect(() => {
        // reset for start fetch data
        lv = 0
        lvRole = 0
        roleArr = []

        checkBtnDrillActive()
        fetchSelectAppCampaign()

        fetchData = {
            ...fetchData,
            drill: statusData.drill,
            asm_code: statusData.asm,
            rsm_code: statusData.rsm,
            tse_code: statusData.tse,
            count: pageSize,
        }

        lv = !statusData.drill ? 0 : !!statusData.rsm ? 2 : !!statusData.asm ? 3 : 1
        lvRole = !statusData.drill ? 0 : !!statusData.rsm ? 2 : !!statusData.asm ? 3 : 1 
        roleArr[!lv ? lv : lv-1] = !!statusData.rsm ? statusData.rsm : !!statusData.asm ? statusData.asm : !!statusData.tse ? statusData.tse : ''

        createChart(mockupdata,showAsPercentage)
        // console.log('lv:',lv,', lvRole:',lvRole,', roleArr',roleArr)
    
      return () => {
        showAsPercentage = false
        isChartLoading = false

        lv = 0
        lvRole = 0
        roleArr = []

        setValue([])
        setCheckboxOption({
            cp_id: false,
            app_id: false
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Check btn drill active
    const checkBtnDrillActive = () => {
        if(lv === lvRole) {
            setBtnDrillActive(false)
        } else {
            setBtnDrillActive(true)
        }
    }

    // HighChart React - Creage chart
    const createChart = (value, percent) => {
        let data = value.data;

        // Get colors[] data
        let colorsArr = []
        // eslint-disable-next-line array-callback-return
        data.all_status.map(item => {
            colorsArr.push(item.color)
        });

        // Get categories[] data
        let categoriesArr = []
        // eslint-disable-next-line array-callback-return
        data.chart.map((item)=>{
            // console.log('item.categories',item)
            if(!item.category && lv) {
                categoriesArr.push(`<span style="cursor: not-allowed; pointer-events: none; color: rgb(165, 165, 165);">ไม่มี ${roleName[lv]}</span>`)
            } else if (!item.category && !lv) {
                categoriesArr.push('Total')
            } else if(item.category !== statusData.employee_role && lv === lvRole) {
                categoriesArr.push(`<span style="cursor: not-allowed;);">${item.category}</span>`)
            } else {
                categoriesArr.push(`<span style="cursor: pointer;);">${item.category}</span>`)
            }
            // categoriesArr.push(!!item.category ? item.category : (!item.category && lv) ? `ไม่มี ${roleName[lv]}` : 'Total')
        })

        // Get series[] data
        let seriesArr = []
        // eslint-disable-next-line array-callback-return
        data.all_status.map((item)=>{
            // eslint-disable-next-line array-callback-return
            // console.log(couponStatusArr.filter(value =>{ return value === item.id}))
            let matchStatus = couponStatusArr.filter(value =>{ return value === item.id}).toString()

            let legendArr = []
            if(percent) {
                // console.log('As Percentage')
                // eslint-disable-next-line array-callback-return
                data.chart.map((itemChart)=>{
                    // legendArr.push(Number(itemChart['persent_'+item.id]))
                    legendArr.push(itemChart[item.id])
                    }
                )
            } else {
                // console.log('As Not Percentage')
                // eslint-disable-next-line array-callback-return
                data.chart.map((itemChart)=> {
                    legendArr.push(itemChart[item.id])
                })
            }
            
            seriesArr.push({
                name: item.name,
                id: item.id,
                data: legendArr,
                visible: (matchStatus === item.id) ? true : false,
                // animation: {
                //     defer: 0,
                //     duration: 0,
                // }
            })
        })

        // console.log(seriesArr)

        // eslint-disable-next-line no-undef
        Highcharts.chart('chart-container', {
            chart: {
                type: 'bar',
                events: {
                    load() {
                        let chart = this;

                        chart.xAxis[0].labelGroup.element.childNodes.forEach(function(label) {
                            label.style.cursor = "pointer";
                            label.style.fontWeight = "bold";

                            
                                label.onclick = function() {
                                    if(!isChartLoading && lv < 4) {
                                        // isChartLoading = true

                                        // console.log('-------------------------------------------------')
                                        // console.log('You clicked:' + this.textContent)
                                        // console.log('appChartFetchData.level:',appChartFetchData.level,', lv:',lv,', level:', appChartFetchData.level)
        
                                        if((this.textContent === statusData.employee_role && lv === lvRole) || lv > lvRole || (this.textContent === 'Total' && !lv)) {
                                            let roleName = this.textContent === 'Total' ? '' : this.textContent
                                            roleArr[lv] = roleName

                                            lv += 1

                                            checkLvDrill(lv, roleName)
                                            checkBtnDrillActive()
                                            
                                        } else {
                                            isChartLoading = false
                                            // setErrModal(true)
                                            // setMsg(`ไม่สามารถทำรายการ ${this.textContent} ได้`)
                                        }
                                        // console.log('lv:',lv,', lvRole:',lvRole,', roleArr:',roleArr)
                                    }
                                }
                        });
                    },
                }
            },
            colors: colorsArr,exporting: {
                enabled: false,
            },
            title: false,
            xAxis: {
                categories: categoriesArr,
            },
            yAxis: {
                min: 0,
                title: {},
                allowDecimals: false,
                stackLabels: {
                  enabled: true,
                  style: {
                    fontWeight: "bold",
                  },
                },
            },
            legend: {
                reversed: true,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 0,
                itemMarginBottom: 12,
            },
            tooltip: {
              formatter: function () {
                return `${this.series.name} ${
                  this.series.chart.options.plotOptions.series.stacking ===
                  "percent"
                    ? `${this.percentage.toFixed(2).replace(/[.,]00$/, "")}% (${
                        (this.y).toLocaleString('en-US')
                      })`
                    : (this.y).toLocaleString('en-US')
                }`;
              },
            },
            plotOptions: {
                series: {
                    stacking: percent? 'percent':'normal'
                },
                bar: {
                    events: {
                      legendItemClick: async (e) => {
                        e.preventDefault();
                        // console.log(e.target.userOptions.id)
                        // console.log(couponStatusArr.includes(e.target.userOptions.id))
                        couponStatusArr.includes(e.target.userOptions.id) ?
                            couponStatusArr = couponStatusArr.reduce((r, v) => v !== e.target.userOptions.id ? r.concat(v): r, [])
                        :
                            couponStatusArr.push(e.target.userOptions.id)

                        setStartValue(0)
                        fetchData = {
                            ...fetchData,
                            start: 0,
                            coupon_status: couponStatusArr,
                        }
                        // if only table load ('table')
                        fetchDataChartTable()

                        // console.log(e.target.userOptions.id, couponStatusArr, fetchData['coupon_status'])
                            
                      }
                    }
                },
            },
            series: seriesArr,
            credits: {
              enabled: false,
            },
        });

        // eslint-disable-next-line no-undef
        Highcharts.setOptions({
            lang: { thousandsSep: "," }
        });
    } 
    
    // React Select - Create group option
    let selectedAll = false
    const createGroup = (groupName, options, setValue) => {
        return {
          label: (() => {
            return (
              <div id={groupName} className={`select-header`}
                onClick={(e) => {
                    document.getElementById(groupName).classList.add('select-all')

                    setValue((value) =>
                        value.concat(options.filter((grpOpt) => !value.includes(grpOpt)))
                    )
                    selectedAll = true
                    // console.log('SelectAll:',options)
                }
                }
              >
                {groupName}
              </div>
            );
          })(),
          options: options
        };
    };

    // Fetch - select value
    const fetchSelectAppCampaign =  async() => {
        //   console.log('location auth:',localStorage.getItem('authLocation'))
        // setExportActive(false)
        setIsLoading(true)
        const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/ecpadvancestatusreport/SelectAppCampaign`, {
            method: 'GET',  
            // body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
  
        res
        .json()
        .then(res => {
            // console.log(res)
            setIsLoading(false)

            // Set response to select option
            let options = [];
            Object.keys(res).forEach(itemKey => {
                let arrValue = []
                // console.log(itemKey+' = '+res[itemKey].length)
                for(let i=0; i<res[itemKey].length; i++) {
                    // console.log(res[itemKey][i])
                    if(itemKey==='AppList') {
                        arrValue.push({label: res[itemKey][i].app_id+' - '+res[itemKey][i].app_name, value: res[itemKey][i].app_id, type: itemKey})
                    } else if(itemKey==='CampaignList') {
                        arrValue.push({label: res[itemKey][i].id+' - '+res[itemKey][i].name, value: res[itemKey][i].id, type: itemKey})
                    }
                }

                options.push(createGroup(itemKey==='AppList'?'Apps':itemKey==='CampaignList'?'Campaigns':null, arrValue, setValue),)
            })

            setOptionList(options)
            
        })
        .catch(err => {
            setIsLoading(false)
            console.log(err);
            setErrModal(true)
        //   window.location.href="/"
        });
    }

    // Fetch - chart, table value *if only update table use ('table')
    const fetchDataChartTable =  async(typeResult) => {
        // console.log('location auth:',localStorage.getItem('authLocation'))
        // setExportActive(false)
        setIsLoading(true)
        const res = await fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/ecpadvancestatusreport/appvsmchart/?cp_id=${fetchData.cp_id}&app_id=${fetchData.app_id}&drill=${fetchData.drill}&coupon_status=${fetchData.coupon_status.toString()}&asm_code=${fetchData.asm_code}&rsm_code=${fetchData.rsm_code}&tse_code=${fetchData.tse_code}&order_by=${fetchData.order_by}&desc=${fetchData.desc}&start=${fetchData.start}&count=${fetchData.count}`, {
            method: 'GET',  
            // body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Authorization": "Bearer "+localStorage.getItem('authToken'),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            setIsLoading(false)
            isChartLoading = false
            // console.log(res)

            if(typeResult==='table') {
                setDataTable(res.data.table)
                setPageTotal(res.data.total_records)

            } else {
                setDataTable(res.data.table)
                setPageTotal(res.data.total_records)
                setAppChartResultData(res)
    
                createChart(res,showAsPercentage)
            }
        })
        .catch(err => {
                setIsLoading(false)
                isChartLoading = false
                console.log(err);
                setErrModal(true)
                createChart(mockupdata,showAsPercentage)
            //   window.location.href="/"
        });
    }

    // Fetch - Export chart, table
    const fetchExportExcel =  async(type) => {
        let typeform = (type === 'chart') ? 'appvsmchart' : 'appvsmtable'
        // setIsLoading(false)
        fetch(`https://${localStorage.getItem('authLocation')}/ecp/api/vsm/ecpadvancestatusreport/${typeform}/download/?cp_id=${fetchData.cp_id}&app_id=${fetchData.app_id}&drill=${fetchData.drill}&coupon_status=${fetchData.coupon_status.toString()}&asm_code=${fetchData.asm_code}&rsm_code=${fetchData.rsm_code}&tse_code=${fetchData.tse_code}&order_by=${fetchData.order_by}&desc=${fetchData.desc}&start=${fetchData.start}&count=${fetchData.count}`, {
            method: 'GET',
            headers: {
              "x-application-secret-key": apiXKey,
              "Authorization": "Bearer "+localStorage.getItem('authToken'),
              'Content-Type': '*/*',
            },
          })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `Stats_${typeform ==='appvsmchart'?'Chart':'Table'}_${statusData.employee_role}.xlsx`,
            );
  
            // Append to html link element page
            document.body.appendChild(link);
  
            // Start download
            link.click();
  
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }

    // Show Data
    const showData = () => {
        // console.log('fetchData',fetchData)
        // console.log('couponStatusArr',couponStatusArr)
        fetchDataChartTable()
    }

    // Check level for fetch chart table
    const checkLvDrill = (lv, role) => {
        // 0 = SA list, 1 = RSM list, 2 = ASM list, 3 =TSE list
        switch (lv) {
            case 0:
                fetchData = {
                    ...fetchData,
                    rsm_code: '',
                    asm_code: '',
                    tse_code: '',
                    drill: 0
                }
                fetchDataChartTable()
                break;

            case 1:
                fetchData = {
                    ...fetchData,
                    rsm_code: '',
                    asm_code: '',
                    tse_code: '',
                    drill: 1
                }
                fetchDataChartTable()
                break;

            case 2:
                fetchData = {
                    ...fetchData,
                    rsm_code: role,
                    asm_code: '',
                    tse_code: '',
                    drill: 1
                }
                fetchDataChartTable()
                break;
            
            case 3:
                fetchData = {
                    ...fetchData,
                    rsm_code: '',
                    asm_code: role,
                    tse_code: '',
                    drill: 1
                }
                fetchDataChartTable()
                break;
        
            default:
                fetchData = {
                    ...fetchData,
                    rsm_code: '',
                    asm_code: '',
                    tse_code: role,
                    drill: 1
                }
                fetchDataChartTable()
                break;
        }
    }

    const previousPage = () => {
        setStartValue(startValue - pageSize)

        fetchData = {
            ...fetchData,
            start: startValue - pageSize,
        }
        fetchDataChartTable('table')
    }

    const nextPage = () => {
        setStartValue(startValue + pageSize)

        fetchData = {
            ...fetchData,
            start: startValue + pageSize,
        }
        fetchDataChartTable('table')
    }

    const handleOrderBy = (valOrderBy) => {
            setOrderData(valOrderBy)
            setStartValue(0)
            // Toggle desc value
            let descValue = !descDataArr[valOrderBy]
            descDataArr[valOrderBy] = descValue?1:0

            fetchData = {
                ...fetchData,
                order_by: valOrderBy,
                desc: descDataArr[valOrderBy],
            }
            fetchDataChartTable('table')
            
            // console.warn(orderData, descDataArr[orderData])
    }

    const handlePageSelect = (e) => {
        // setPageSelect(e.target.value)
        // console.log('pageSelect',e.target.value,'* PageSize:',e.target.value*pageSize)
        setStartValue(e.target.value * pageSize)

        fetchData = {
            ...fetchData,
            start: e.target.value * pageSize,
        }
        fetchDataChartTable('table')
    }

    const genOptionPage = (totalPage, currentPage) => {
        let optionArr = []

        for(let i=0; i<totalPage; i++) {
            optionArr.push(i+1)
        }

        return (
            <select value={currentPage} onChange={handlePageSelect}>
                {optionArr.map((item,i) => (
                    <option key={i} value={i}>
                    {item}
                    </option>
                ))}
            </select>
        )
    }
    
    const handleOnChangeCheckbox = (e) => {
        setValue([])
        setCheckboxOption({
            ...checkboxOption,
            [e.target.name]: e.target.checked
        })
        
        if(fetchData['cp_id'] !== 'All') {
            fetchData['cp_id'] = ''
        }

        if(fetchData['app_id'] !== 'All') {
            fetchData['app_id'] = ''
        }
        
        fetchData = {
            ...fetchData,
            [e.target.name]: e.target.checked ? 'All' : ''
        }
    }

    const handleOnChangeSelect = () => {
        // React Select - Remove class select-all
        if(!selectedAll) { 
            var elems = document.querySelectorAll(".select-header.select-all");
            [].forEach.call(elems, function(el) {
                el.classList.remove("select-all");
            });

            selectedAll = false
         } 
    }

    // Set value of checkbox percentage
    const handleOnChangeCheckboxPercentage = (e) => {
        // console.log(e.target.name,e.target.checked)
        setCheckboxPercentage(e.target.checked)
        // showAsPercentage(e.target.checked)
        showAsPercentage = e.target.checked
        createChart(appChartResultData,showAsPercentage)
    }

    const handleDrillup = () => {
        lv -= 1
        checkBtnDrillActive()
        checkLvDrill(lv,roleArr[lv-1])
        // console.log('lv:',lv,', drillup:',roleArr[lv])
    }

    Modal.setAppElement('#root');

    return (
        <div className="statistic-content">
            {
                isLoading ? <div className="chart-overlay"><img src={IconLoading} alt="...Loading..." /></div> : null
            }
            <div className="header-content">
                <div className="left-content">
                    <h1>Stats</h1>
                    <p><span className="font-blue" onClick={onClick}>HOME</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;STATS</p>
                </div>
                <div className="right-content">
                </div>
            </div>

            <div className="control-data mg-t-30">
                <div className="input-checkbox-stats" onChange={handleOnChangeCheckbox}>
                    <label className="container">All Campaigns
                        <input type="checkbox" name="cp_id" />
                        <span className="checkmark"></span>
                    </label>
                    <label className="container">All Apps
                        <input type="checkbox" name="app_id" />
                        <span className="checkmark"></span>
                    </label>
                </div>

                <div className="input-select-stats">    
                    <Select
                        hideSelectedOptions={false}
                        onChange={(option) => {
                            // console.log(option);

                            // Set Set cp_id, app_id to Array
                            let appIdArr = []
                            let cpIdArr = []
                            option.forEach(item => {
                                // console.log(item.value)
                                if(item.type === 'AppList') {
                                    appIdArr.push(item.value)
                                } else  if(item.type === 'CampaignList'){
                                    cpIdArr.push(item.value)
                                }
                            });

                            // Set cp_id, app_id for fetch appchart
                            // setAppChartFetchData({
                            //     ...appChartFetchData,
                            //     cp_id: cpIdArr.toString(),
                            //     app_id: appIdArr.toString()
                            // })
                            fetchData['cp_id'] = cpIdArr.toString()
                            fetchData['app_id'] = appIdArr.toString()

                            handleOnChangeSelect();
                            return setValue(option);
                        }}
                        isMulti
                        //menuIsOpen={false} // true:open for test
                        options={optionList}
                        value={value}
                        styles={customStyles}
                        allowSelectAll={true}
                        closeMenuOnSelect={false}
                        isDisabled={checkboxOption.cp_id || checkboxOption.app_id} 
                        blurInputOnSelect={false}
                    />
                </div>
                <button className="btn-round _blue" type="button" onClick={()=>{showData()}}>
                    <span>Show</span>
                </button>
            </div>

            <div className="chart-area mg-t-30">

            <div className="chart-row">
                    <div className="chart-row-left">
                        <button className="chart-btn-drill" onClick={()=>{handleDrillup()}}  style={btnDrillActive ? {pointerEvents: 'auto', cursor: 'pointer', opacity: '1'} : {pointerEvents: 'none', cursor: 'not-allowed', opacity: '0.5'} }>
                            <img src={IconDrillup} alt="drillup"/>
                        </button>
                        {/* <span>drill level: {appChartFetchData.drill }</span> */}
                    </div>
                    <div className="chart-row-right">
                        <div className="chart-btn-switch">
                            <span>Show Percentage</span>
                            <label className="switch" onChange={handleOnChangeCheckboxPercentage}>
                                <input type="checkbox" name="percentage"/>
                                <span className="slider round"></span>
                            </label>
                            <span>{checkboxPercentage?'ON':'OFF'}</span>
                        </div>
                        <button className="btn-xls for-stats"  type="button" onClick={()=>{fetchExportExcel('chart')}}>
                            <img src={IconXls} alt="reload" />
                            <span>Export to Excel</span>
                        </button>
                    </div>
                </div>

                <div id="chart-container" className="chart-container"></div>
            </div>

            <div className="table-area mg-t-30 mg-b-40">
                <div className="table-export">
                    <button className="btn-xls for-stats"  type="button"  onClick={()=>{fetchExportExcel('table')}}>
                        <img src={IconXls} alt="reload" />
                        <span>Export to Excel</span>
                    </button>
                </div>
                <div style={{overflow: 'auto'}}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('ecp_id')}>รหัสคูปอง 
                                    { orderData === 'ecp_id' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.ecp_id ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '100px'}} onClick={()=>handleOrderBy('face_value')}>มูลค่า
                                    { orderData === 'face_value' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.face_value ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('topup')}>Top up
                                    { orderData === 'topup' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.topup ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('minimum_purchase')}>ซื้อขั้นต่ำ (แถว)
                                    { orderData === 'minimum_purchase' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.minimum_purchase ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('end_date')}>วันหมดอายุ
                                    { orderData === 'end_date' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.end_date ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '120px'}} onClick={()=>handleOrderBy('ecp_status')}>สถานะ
                                    { orderData === 'ecp_status' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.ecp_status ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('isms_code')}>ISMS
                                    { orderData === 'isms_code' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.isms_code ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('shop_name')}>ชื่อร้านค้า
                                    { orderData === 'shop_name' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.shop_name ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('province')}>จังหวัด
                                    { orderData === 'province' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.province ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('district')}>อำเภอ
                                    { orderData === 'district' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.district ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('subdistrict')}>ตำบล
                                    { orderData === 'subdistrict' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.subdistrict ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('group_type')}>DC
                                    { orderData === 'group_type' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.group_type ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                                <th style={{minWidth: '140px'}} onClick={()=>handleOrderBy('role')}>role
                                    { orderData === 'role' ? <span style={{fontSize: '0.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;{ descDataArr.role ? ' ▲' : ' ▼'}</span> : null }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            isLoading ?
                            <tr>
                                <td style={{padding: '80px'}} colSpan={9}>...Loading...</td>
                            </tr>
                            :
                            dataTable.length ?
                                dataTable.map((row, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{row.ecp_id}</td>
                                            <td>{row.face_value}</td>
                                            <td>{row.topup}</td>
                                            <td>{row.minimum_purchase}</td>
                                            <td>{moment(row.end_date).format('DD MMM YYYY HH:mm')==='Invalid date'?'':moment(row.end_date).format('DD MMM YYYY HH:mm')}</td>
                                            <td>{row.coupon_status}</td>
                                            <td>{row.isms_code}</td>
                                            <td>{row.shop_name}</td>
                                            <td>{row.province}</td>
                                            <td>{row.district}</td>
                                            <td>{row.subdistrict}</td>
                                            <td>{row.group_type}</td>
                                            <td>{row.role}</td>
                                        </tr>
                                    )
                                })
                            : <tr><td colSpan={9}>No Data</td></tr>
                        }
                        </tbody>
                    </table>
                </div>
                <div className="pagination mg-t-10">
                    <div className="pagination-left">

                        <span>Rows per page:</span>
                        <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                            setStartValue(0)

                            fetchData = {
                                ...fetchData,
                                start: 0,
                                count: Number(e.target.value),
                            }
                            fetchDataChartTable('table')
                        }}
                        >
                        {[5, 10, 15, 20, 25, 30].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                            {pageSize}
                            </option>
                        ))}
                        </select>

                    </div>
                    <div className="pagination-right">
                        <button onClick={() => previousPage()}  disabled={(startValue/pageSize)+1 === 1}>
                            {'<'}
                        </button>{' '}
                        <span>
                            Page{' '}
                            {/* <strong> */}
                                {/* {Math.ceil((startValue/pageSize)+1)} of {Math.ceil(pageTotal/pageSize)} */}
                            {/* </strong> */}
                            
                            {
                                genOptionPage(Math.ceil(pageTotal/pageSize), Math.ceil((startValue/pageSize)))
                            }

                            of {Math.ceil(pageTotal/pageSize)}
                            {' '}
                        </span>
                        <button onClick={() => nextPage()}  disabled={(startValue/pageSize)+1 === Math.ceil(pageTotal/pageSize)}>
                            {'>'}
                        </button>
                    </div>
                </div>
            </div>

            <Modal 
              isOpen={errModal} 
              className="_modal box-style modal-date" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 3
                  }}
              }
          >
              <div className="_date-modal center" style={{margin: '20px 10px 10px'}}>

                  <h3 style={{lineHeight: '1'}}>{msg}</h3>
                  <br/>
                  <ButtonRound text="ตกลง" color="blue" onClick={()=>{setErrModal(false); setIsLoading(false); /*console.log(state[0].startDate);  console.log(state[0].endDate);*/}}/>
                    
              </div>
          </Modal>
        </div>
    )
}

export default Statistic