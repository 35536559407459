import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import ButtonRound from '../components/ButtonRound';
import LoginLogo from '../assets/img/ic-ticket.png';
import AuthContext from '../components/AuthContext';

function Login() {
    const history = useHistory();
    const { setAuthData } = useContext(AuthContext)

    const [dataLogin, setDataLogin] = useState({
        username: '',
        password: '',
    })

    const [errShow, setErrShow] = useState(false)
    const [errMessage, setErrMessage] = useState('ไม่สามารถเข้าระบบได้')
    

    const fetchLogin =  async() => {
        let apiLocation = '';
        let apiXKey = ''
        
        console.log('window.location.origin',window.location.origin);

        switch(window.location.origin) {
            // Local
            case "http://localhost:3000": 
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;

            case "http://localhost:3001": 
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;

            // Staging
            case "https://vsmecoupon-sandbox.vivi-th.com":
            apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
            apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
            break;

            // Production
            default:
            apiLocation = 'api.vipwallet.vivi-th.com';
            apiXKey = "756dc69c-4c3e-4724-aa3d-0cf27010c102";
            break;
        }

        const res = await fetch(`https://${apiLocation}/ecp/api/vsm/login`, {
            method: 'POST',
            body: JSON.stringify(dataLogin),
            headers: {
                "x-application-secret-key": apiXKey,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
        .json()
        .then(res => {
            console.log('data login:',res)
            if (res.success === 0) {
                setErrShow(true);

            } else {
                const data = res;
                // console.log(data)
                setAuthData(data)
                history.push('/home')
                localStorage.setItem('authToken',data.token)
                localStorage.setItem('authXkey',apiXKey)
                localStorage.setItem('authLocation',apiLocation)
                // localStorage.setItem('authData',JSON.stringify(data))
            }

        })
        .catch(err => {
            console.log(err);
            setErrShow(true)
            setErrMessage('username หรือ รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่')
        });

    }

    const handleChange = (e) => {
        setErrShow(false)
        const { name, value } = e.target
        setDataLogin({
            ...dataLogin,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchLogin()
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <h1>PHILIP MORRIS INTERNATIONAL</h1>
                    <img src={LoginLogo} alt="icon" />
                    <h2>E-COUPON MANAGEMENT SYSTEM</h2>
                </div>
                <div className="login-form">
                    <form onSubmit={handleSubmit}>
                        <input type="text" name="username" placeholder="username" required onChange={handleChange} />
                        <input type="password" name="password" placeholder="password" required onChange={handleChange} />
                        <p className="font-black">
                            { 
                                errShow ? errMessage : null
                            }
                            &nbsp;
                        </p>
                        <ButtonRound color="blue" text="Sign in" onSubmit={handleSubmit} />
                    </form>
                </div>
                <p className="font-white">Sign into your pages account</p>
            </div>

            
        </div>
    )
}

export default Login
