import React from 'react'

function ColumnFilter({column}) {
    const { filterValue, setFilter } = column
    return (
        <div>
            {/* search: {' '} */}
            {/* <input value={filterValue || ''} onChange={(e)=>setFilter(e.target.value)} /> */}
            <select className="input-filter-status" value={filterValue || ""} onChange={e => {setFilter(e.target.value); /*console.log('search',e.target.value)*/}}>
                
                <option value="">ทั้งหมด</option>
                <option value="พร้อมสแกน">พร้อมสแกน</option>
                <option value="รอแลกคะแนน">รอแลกคะแนน</option>
                <option value="รอแลกคะแนน(หมดอายุ)">รอแลกคะแนน(หมดอายุ)</option>
                <option value="รอแลกคืน">รอแลกคืน</option>
                <option value="รอเก็บ">รอเก็บ</option>
                <option value="รอเก็บ(หมดอายุ)">รอเก็บ(หมดอายุ)</option>
                <option value="กำลังแลกคืน(ไม่สแกน)">กำลังแลกคืน(ไม่สแกน)</option>
                <option value="กำลังแลกคืน">กำลังแลกคืน</option>
                <option value="แลกคืนแล้ว">แลกคืนแล้ว</option>
                <option value="หมดอายุ">หมดอายุ</option>
                
            </select>
        </div>
    )
}

export default ColumnFilter
